body {
  /* background-color: #8FAD88; */
  font-size: 48px;
  color: white;
  font-family: Kanit, sans-serif;
}

.step {
  width: 800px;
  height: 600px;
  /* color: black; */
}

.bg-white {
  background-color: rgba(255, 255, 255, 0.8);
  color: #000000;
}

h1,
.h1 {
  font-size: 105px;
}

h2,
.h2 {
  font-size: 92px;
}

h3,
.h3 {
  font-size: 81px;
}

.text-small {
  font-size: 32px;
}

.box {
  display: block;
  width: 800px;
  height: 600px;
  padding: 30px 50px;
  background-color: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  font-size: 40px;
  color: rgb(102, 102, 102);
}

.welcome {
  width: 800px;
  text-align: center;
}

.welcome h1 {
  margin-top: 150px;
}

.welcome h3 {
  position: relative;
  top: -120px;
}

.my-name {
  text-align: center;
}

.my-name h3 {
  margin-top: 0px;
}
